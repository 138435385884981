import React from 'react'
import QRCodeReact from 'qrcode.react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { BoxFooter } from './Box'
import { AddToAppleWalletButton } from './AddToAppleWalletButton'

export const QRCode = () => {
  const { cardDetails, toggleQRCode, showAddToNativeWallet } =
    useLoyaltyContext()
  const cardNumber = cardDetails?.cardNumber
  const cardToken = cardDetails?.cardToken

  return (
    <>
      <div className='px-6 pb-6'>
        <div className='max-w-sm mx-auto'>
          <p className='type-default text-default text-center pb-8'>
            Show to staff in store to connect an order to your loyalty account.
            If using a linked card this happens automatically.
          </p>
          <div className='w-42 h-42 mb-4 flex justify-center'>
            <QRCodeReact
              data-testid='loyalty-qr-code'
              value={cardNumber}
              size={168}
            />
          </div>
          <p className='type-default text-default text-center'>
            {cardNumber.replace(/(\d{4})/g, '$1 ')}
          </p>
          {showAddToNativeWallet && (
            <div className='w-42 h-13 mt-2 mx-auto'>
              <AddToAppleWalletButton
                testId='add-to-apple-wallet-qr-code'
                cardToken={cardToken}
              />
            </div>
          )}
        </div>
      </div>
      <BoxFooter>
        <div
          id='lw-click-qrcode-back'
          className='group flex items-center justify-center py-5 px-6 cursor-pointer'
          onClick={toggleQRCode}
          role='button'
          tabIndex='0'
        >
          <p className='type-default text-link font-bold group-hover:underline'>
            Back to loyalty overview
          </p>
        </div>
      </BoxFooter>
    </>
  )
}
