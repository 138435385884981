import React from 'react'
import QRCodeIcon from '../../images/qr-code.svg'
import { BoxFooter } from './Box'

export const QRCodeBoxFooter = ({ onClick }) => {
  return (
    <BoxFooter>
      <div
        id='lw-click-qrcode-footer-open'
        className='group flex items-center justify-center py-5 px-6 cursor-pointer'
        onClick={onClick}
        role='button'
        tabIndex='0'
      >
        <div className='w-5 h-5'>
          <QRCodeIcon />
        </div>
        <p className='type-default text-link font-bold group-hover:underline pl-2'>
          Show digital loyalty card
        </p>
      </div>
    </BoxFooter>
  )
}
