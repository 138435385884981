import React, { useEffect } from 'react'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { trackEvent } from '../../utils/tracking'

interface SmsSubscriptionToggleProps {
  isActive: boolean
  onChange: (isActive: boolean) => void
}

export const SmsSubscriptionToggle = ({
  isActive,
  onChange
}: SmsSubscriptionToggleProps) => {
  useEffect(() => {
    trackEvent({ name: 'Sms Subscription Toggle' })
  }, [])
  return (
    <div className='w-full flex flex-col justify-center border bg-gray-0 rounded-lg p-4'>
      <div className='flex justify-between items-center mb-2'>
        <p className='type-default font-semibold text-default mr-4'>
          Get special offers through SMS
        </p>
        <ToggleSwitch
          size='xxs'
          isActive={isActive}
          onChange={() => onChange(!isActive)}
          aria-label='Subscribe to SMS marketing toggle'
        />
      </div>
      <p className='type-caption text-secondary'>
        I would also like to receive recurring marketing texts with special
        offers and discounts from this restaurant or restaurant group.
      </p>
    </div>
  )
}
