import React from 'react'
import { NumberInput as NI } from '@toasttab/buffet-pui-text-input'

export const NumberInput = ({
  ariaLabel,
  placeholder,
  disabled,
  value,
  invalid,
  onChange
}) => {
  const onChangeInput = (e) => {
    const newValue = e?.value
    const intValue = newValue === '' ? undefined : parseInt(newValue, 10)
    onChange(intValue)
  }

  return (
    <NI
      aria-label={ariaLabel}
      placeholder={placeholder}
      disabled={disabled}
      invalid={invalid}
      value={value}
      onChange={onChangeInput}
    />
  )
}
