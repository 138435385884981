import React from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import { DeleteIcon, CheckCircleSelectedIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { displayCreditCardName } from '../../utils/functions'

export const CreditCardConfirmModal = () => {
  const {
    creditCardModal,
    userDetailsModal,
    closeCreditCardModal,
    creditCardAction
  } = useLoyaltyContext()

  if (!creditCardModal.open || userDetailsModal.open) {
    return null
  }

  const { selectedCardData, verifying, loading, errorMessage } = creditCardModal
  const { cardType, lastFourDigits, hasExpired } = selectedCardData

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={true}
      onRequestClose={loading ? undefined : closeCreditCardModal}
      size={Modal.Size.sm}
      testId='card-confirm-modal'
    >
      <Modal.Header>
        <h4 className='type-headline-4 text-default font-bold pr-2'>
          {verifying ? 'Verify' : 'Remove'} card
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className='relative'>
          {loading && (
            <div
              role='progressbar'
              aria-label='loading icon'
              aria-valuetext='Loading'
              className='absolute top-0 left-0 right-0 bottom-0 bg-lighten-60 flex items-center justify-center'
            >
              <MerryGoRound size={MerryGoRound.Size.md} />
            </div>
          )}
          {errorMessage && (
            <Alert variant='error' className='w-full mb-4' showIcon>
              {errorMessage}
            </Alert>
          )}
          <p className='type-default text-default pb-12'>
            {verifying
              ? 'After the verification you are able to redeem rewards using this card.'
              : 'Are you sure you want to remove this card? You will no longer earn loyalty points automatically.'}
          </p>
          <div className='pb-6 border-b mb-12 type-default text-default'>
            <p className='font-bold'>{displayCreditCardName(cardType)}</p>
            <div className='flex justify-between items-center'>
              <p className='whitespace-no-wrap pr-2' aria-hidden={true}>
                •••• •••• •••• {lastFourDigits}
              </p>

              <p className='whitespace-no-wrap' aria-hidden={true}>
                exp: {hasExpired ? 'expired' : 'valid'}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {verifying ? (
          <Button
            id='lw-click-card-modal-verify'
            iconLeft={<CheckCircleSelectedIcon />}
            disabled={loading}
            onClick={creditCardAction}
            testId='verify-confirm-button'
          >
            Verify card
          </Button>
        ) : (
          <Button
            id='lw-click-card-modal-remove'
            variant={Button.Variant.destructive}
            iconLeft={<DeleteIcon />}
            disabled={loading}
            onClick={creditCardAction}
            testId='remove-confirm-button'
          >
            Remove card
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
