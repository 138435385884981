import React from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { isValidEmail } from '@toasttab/email-validation'

export const EmailInput = ({ disabled, value, onChange }) => {
  const onChangeInput = (e) => {
    onChange(e?.target?.value)
  }

  const isInvalid = !disabled && value && !isValidEmail(value)

  return (
    <TextInput
      aria-label='email'
      placeholder='Email'
      type='text'
      disabled={disabled}
      invalid={isInvalid}
      value={value}
      onChange={onChangeInput}
    />
  )
}
