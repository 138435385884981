import React from 'react'
import PoweredByToast from '../../images/powered-by-toast.svg'

export const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <div className='py-8 border-t mt-8 mx-4'>
      <a
        id='lw-click-footer-toast'
        href='http://pos.toasttab.com/?source=footer'
        rel='noopener noreferrer'
        target='_blank'
        aria-label='powered by toast'
        className='hover:no-underline group'
      >
        <PoweredByToast className='mx-auto fill-current text-gray-75 group-hover:text-primary-75' />
        <p className='type-subhead text-center text-gray-75 group-hover:text-primary-75 pt-2'>
          © {year} Toast, Inc
        </p>
      </a>
    </div>
  )
}
