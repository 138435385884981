import React from 'react'
import pluralize from 'pluralize'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const RecentActivity = () => {
  const {
    cardDetails,
    rewardsConfig: { accrualType, rewardsVisitConfig }
  } = useLoyaltyContext()
  const loyaltyItems = cardDetails?.recentLoyaltyTransactions || []
  const giftCardItems = cardDetails?.recentStoredTransactions || []

  if (!loyaltyItems.length && !giftCardItems.length) {
    return (
      <p className='type-default text-default p-6'>
        Looks like there's no transactions so far.
      </p>
    )
  }

  const isVisitAccrual = accrualType === 'VISIT'

  const getAmount = (transaction) => {
    if (isVisitAccrual) {
      const visitEquivalent =
        transaction.amount / rewardsVisitConfig?.pointsAccruedPerVisit

      return pluralize('Visit', parseInt(Math.floor(visitEquivalent)), true)
    }
    return pluralize('Point', parseInt(transaction.amount), true)
  }

  const formattedItems = loyaltyItems
    .map((l) => ({
      ...l,
      amount: getAmount(l)
    }))
    .concat(giftCardItems)
    .map((i) => ({ ...i, date: toDate(i.transactionDate) }))

  const sortedItems = sortByTransactionDate(formattedItems)

  const formatTransactionType = (transactionType) => {
    if (transactionType === 'Add Points') {
      return 'Add Value'
    }
    if (transactionType === 'Adjust Points') {
      return 'Adjust Balance'
    }
    return transactionType
  }
  return (
    <ul className='px-6'>
      {sortedItems.map(({ amount, transactionDate, date, transactionType }) => {
        return (
          <li
            key={`history-item-${transactionDate}`}
            className='py-6 border-b text-default type-default'
          >
            <div className='flex justify-between font-bold'>
              <div className='pr-2'>
                {formatTransactionType(transactionType)}
              </div>
              <div className='whitespace-no-wrap'>{incOrDec(amount)}</div>
            </div>
            <div>{displayDate(date)}</div>
          </li>
        )
      })}
    </ul>
  )
}

const sortByTransactionDate = (items) => {
  return [...items].sort((a, b) => {
    return b.date - a.date
  })
}

const toDate = (dateStr = '') => {
  let fixedDateString = dateStr.replace(' ', 'T')
  // The datestring from API looks like "2022-11-17 03:05:23.523", with no indication of timezone.
  // Adding a "Z" will tell the Date constructor that this is UTC, and so the Date will shift it to local timezone properly.
  // End string will look like "2022-11-17T03:05:23.523Z" which in EST is the 16th at 10:05pm, not 17th at 03:05am.
  if (
    !fixedDateString.includes('UTC') &&
    !fixedDateString.includes('Z') &&
    !fixedDateString.includes('z')
  ) {
    // Future proofing - if backend is fixed and sends timezone information, don't duplicate
    fixedDateString += 'Z'
  }
  return new Date(fixedDateString)
}

const displayDate = (dateObject) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const lang = 'en-EN'
  return dateObject.toLocaleDateString(lang, options)
}

const incOrDec = (str) => {
  if (str.substring(0, 1) !== '-') {
    return '+' + str
  }
  return str
}
