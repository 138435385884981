import React, { StrictMode } from 'react'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { LoyaltyPage } from '../components/LoyaltyPage/LoyaltyPage'

export const App = () => (
  <StrictMode>
    <Router>
      <Route exact path='/loyalty/welcome/:cardToken' component={LoyaltyPage} />
    </Router>
  </StrictMode>
)
