import React from 'react'
import {
  ArrowForwardIcon,
  OrdersIcon,
  RestaurantIcon
} from '@toasttab/buffet-pui-icons'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { WaysToItem } from './WaysToItem'
import { QRCodeBoxFooter } from './QRCodeBoxFooter'
import { AddToAppleWalletButton } from './AddToAppleWalletButton'

export const WaysToRedeem = () => {
  const {
    isOOAvailable,
    restaurant,
    cardDetails,
    toggleQRCode,
    showAddToNativeWallet
  } = useLoyaltyContext()

  const shortUrl = restaurant?.shortUrl
  const website = restaurant?.restaurantWebsite
  const name = restaurant?.restaurantName
  const cardToken = cardDetails?.cardToken

  return (
    <>
      <div className='p-6'>
        <p className='type-large text-default font-bold pb-2'>Ways to redeem</p>
        <p className='type-default text-secondary'>
          Rewards can be redeemed at checkout after connecting to a loyalty
          account.
        </p>
        {isOOAvailable && shortUrl && (
          <a
            id='lw-click-ways-to-redeem-oo'
            href={`/${shortUrl}/v3?utmSource=loyalty-web`}
            rel='noopener noreferrer'
            target='_blank'
            className='no-underline'
            aria-label='online ordering'
          >
            <WaysToItem
              icon={<OrdersIcon />}
              title={
                <span className='whitespace-no-wrap'>
                  Online <ArrowForwardIcon />
                </span>
              }
              subtitles={[
                'Create an online ordering account with the email or phone number linked to your loyalty account and log in to order.'
              ]}
              isLink={true}
            />
          </a>
        )}
        {website ? (
          <a
            id='lw-click-ways-to-redeem-website'
            href={website}
            rel='noopener noreferrer'
            target='_blank'
            className='no-underline'
            aria-label={`${name} website`}
          >
            <WaysToItem
              icon={<RestaurantIcon />}
              title={
                <span className='whitespace-no-wrap'>
                  In Store <ArrowForwardIcon />
                </span>
              }
              subtitles={[
                'Use a linked payment card to connect to your account automatically or present your digital loyalty card to staff.'
              ]}
              isLink={true}
            />
          </a>
        ) : (
          <WaysToItem
            icon={<RestaurantIcon />}
            title='In Store'
            subtitles={[
              'Use a linked payment card to connect to your account automatically or present your digital loyalty card to staff.'
            ]}
          />
        )}
        {showAddToNativeWallet && (
          <div className='pt-4 mx-auto flex justify-center'>
            <AddToAppleWalletButton
              testId='add-to-apple-wallet-ways-to-redeem'
              cardToken={cardToken}
            />
          </div>
        )}
      </div>
      {cardDetails?.cardNumber && <QRCodeBoxFooter onClick={toggleQRCode} />}
    </>
  )
}
