import React from 'react'
import { LoyaltyContextProvider } from '../../context/LoyaltyContext'
import { LoyaltyView } from './LoyaltyView'

export const LoyaltyPage = () => {
  return (
    <LoyaltyContextProvider>
      <LoyaltyView />
    </LoyaltyContextProvider>
  )
}
