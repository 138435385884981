import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-lwr',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: '9e4d7f2981a240678514a0f123cdd098@o37442',
    projectId: '1411128',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'loyalty-web'
