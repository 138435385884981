import React from 'react'
import cx from 'classnames'

export const WaysToItem = ({ icon, title, subtitles, isLink }) => {
  return (
    <div className='mt-6 pt-6 border-t flex group'>
      <div
        className='bg-info-0 w-12 h-12 rounded-full mr-4 flex items-center justify-center text-primary-75'
        style={{ minWidth: '3rem' }}
      >
        {icon}
      </div>
      <div className='flex-grow'>
        <p
          className={cx('type-default text-default font-bold', {
            'text-link group-hover:underline': isLink
          })}
        >
          {title}
        </p>
        {subtitles &&
          subtitles.map((subtitle, i) => {
            return (
              subtitle && (
                <p
                  key={`earn-sub-${i}`}
                  className='type-default text-secondary'
                >
                  {subtitle}
                </p>
              )
            )
          })}
      </div>
    </div>
  )
}
