import React, { useState } from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'

export const Descriptions = () => {
  const { rewardsConfig } = useLoyaltyContext()
  const [showMore, setShowMore] = useState(false)

  const additionalDescription = rewardsConfig?.additionalDescription
  const programDescription = rewardsConfig?.programDescription

  const toggleShowMore = () => setShowMore((current) => !current)

  return (
    <div className='px-6 pb-10'>
      <div className='max-w-sm mx-auto'>
        <p
          data-testid='description'
          className='type-default text-default text-center'
        >
          {programDescription}
        </p>
        {showMore && (
          <p className='type-default text-default text-center pt-6'>
            {additionalDescription}
          </p>
        )}
        {additionalDescription && (
          <p
            id='lw-click-toggle-additional-description'
            className='text-primary-75 type-default text-center font-bold cursor-pointer hover:underline outline-none mt-6'
            onClick={toggleShowMore}
            role='button'
            tabIndex='0'
          >
            {showMore ? 'Show less' : 'Learn more'}
          </p>
        )}
      </div>
    </div>
  )
}
