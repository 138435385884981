import React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { defaultErrorMessage } from '../../services/api'
import { Loading } from './Loading'
import { Layout } from './Layout'
import { Box } from './Box'
import { Header } from './Header'
import { Descriptions } from './Descriptions'
import { LoyaltyError } from './LoyaltyError'
import { PointsAndRewards } from './PointsAndRewards'
import { WaysToEarn } from './WaysToEarn'
import { WaysToRedeem } from './WaysToRedeem'
import { QRCode } from './QRCode'
import { GiftCardBalance } from './GiftCardBalance'
import { LoyaltyTabs } from './LoyaltyTabs'
import { CreditCardConfirmModal } from './CreditCardConfirmModal'
import { UserDetailsModal } from './UserDetailsModal'
import { AppDownload } from './AppDownload'

export const LoyaltyView = () => {
  const {
    loading,
    initialErrorMessage,
    restaurant,
    cardDetails,
    rewardsConfig,
    showQrCode,
    showAppDownload
  } = useLoyaltyContext()

  if (loading) {
    return <Loading />
  }

  if (initialErrorMessage || !restaurant?.restaurantName) {
    return (
      <div className='p-4'>
        <Alert variant='error' className='w-full' showIcon>
          {initialErrorMessage || defaultErrorMessage}
        </Alert>
      </div>
    )
  }

  if (!rewardsConfig?.isLoyaltyEnabled) {
    return <LoyaltyError message='This loyalty program is currently inactive' />
  }

  if (cardDetails?.frozen) {
    return <LoyaltyError message='This card is frozen' />
  }

  const hasGiftCardBalance = !!cardDetails?.recentStoredTransactions?.length

  return (
    <Layout showTerms={true}>
      <CreditCardConfirmModal />
      <UserDetailsModal />
      <Box>
        <Header />
        {showQrCode ? <QRCode /> : <Descriptions />}
      </Box>
      {!showQrCode && (
        <>
          <Box>
            <PointsAndRewards />
            {showAppDownload && <AppDownload />}
          </Box>
          {hasGiftCardBalance && (
            <Box>
              <GiftCardBalance />
            </Box>
          )}
          <Box>
            <WaysToEarn />
          </Box>
          <Box>
            <WaysToRedeem />
          </Box>
          <LoyaltyTabs />
        </>
      )}
    </Layout>
  )
}
