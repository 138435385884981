import React from 'react'
import { Select } from '@toasttab/buffet-pui-dropdowns'

export const Dropdown = ({ testId, disabled, options, value, onChange }) => {
  const onChangeSelect = (newOption) => {
    onChange(newOption.value)
  }

  const option = findOption(options, value)

  return (
    <Select
      testId={testId}
      options={options}
      disabled={disabled}
      value={option || ''}
      onChange={onChangeSelect}
      itemToValue={(item) => item}
    />
  )
}

const findOption = (options, value) => {
  return options.find((o) => o.value === value)
}
