import React from 'react'
import cx from 'classnames'
import { LockLockedIcon } from '@toasttab/buffet-pui-icons'

export const ProgressChart = ({ value, max, label, withLock }) => {
  const percentage = Math.floor((value / max) * 100)
  const bgColor = withLock ? 'bg-gray-50' : 'bg-white'
  return (
    <div
      className='flex items-center overflow-hidden'
      role='progressbar'
      aria-label={`${label} progress`}
      aria-valuetext={`${percentage} percent`}
    >
      <div className={cx('h-3 w-3 rounded-full', bgColor)}>
        {value > 0 && (
          <div className='h-3 w-3 rounded-full bg-primary-75'></div>
        )}
      </div>
      <div className={cx('h-1 flex-grow', bgColor)}>
        {value > 0 && (
          <div
            className='h-1 bg-primary-75'
            style={{ width: `${percentage}%` }}
          ></div>
        )}
      </div>
      {withLock ? (
        <div
          className={cx(
            'h-6 w-6 flex items-center justify-center rounded-full',
            bgColor
          )}
        >
          <LockLockedIcon size='xs' className='text-white' alt='lock icon' />
        </div>
      ) : (
        <div className={cx('h-3 w-3 rounded-full', bgColor)}></div>
      )}
    </div>
  )
}
