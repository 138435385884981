import React from 'react'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { numberToFixed } from '../../utils/functions'
import { BoxFooter } from './Box'

export const GiftCardBalance = () => {
  const { cardDetails } = useLoyaltyContext()

  const balance = cardDetails?.giftCardBalance
  const link = cardDetails?.giftCardAddValueLink

  return (
    <>
      <div className='px-6 py-8'>
        <div className='max-w-sm mx-auto'>
          <h3 className='type-headline-3 text-default font-bold text-center'>
            ${numberToFixed(balance, 2)}
          </h3>
          <p className='type-default text-secondary text-center'>
            Gift Card Balance
          </p>
        </div>
      </div>
      <BoxFooter>
        <a
          id='lw-click-gift-card-add-balance'
          href={link}
          rel='noopener noreferrer'
          target='_blank'
        >
          <p className='type-default font-bold text-link text-center py-5 px-6'>
            Add to balance
          </p>
        </a>
      </BoxFooter>
    </>
  )
}
