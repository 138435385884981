import React from 'react'
import AddToAppleWalletImage from '../../images/add-to-apple-wallet.svg'
import { api } from '../../services/api'

export const AddToAppleWalletButton = ({ cardToken, testId }) => {
  return (
    <a href={api.createWalletPass(cardToken)} download data-testid={testId}>
      <AddToAppleWalletImage className={'h-13 w-full'} />
    </a>
  )
}
