import React from 'react'
import cx from 'classnames'
import { useLoyaltyContext } from '../../context/LoyaltyContext'
import { Footer } from './Footer'
import { Terms } from './Terms'

export const Layout = ({ showTerms, children }) => {
  const { restaurant } = useLoyaltyContext()

  const backgroundUrl = restaurant?.bannerImageUrl

  return (
    <div className='relative w-full min-h-screen flex flex-col justify-between'>
      <div
        className={cx(
          'absolute top-0 left-0 w-full bg-primary-100 bg-no-repeat bg-center bg-cover h-52',
          {
            'h-68': backgroundUrl
          }
        )}
        style={
          backgroundUrl && {
            backgroundImage: `url(${backgroundUrl})`
          }
        }
        data-testid='background-banner'
      ></div>
      <div
        className={cx('relative w-full max-w-xl mx-auto px-4 mt-12', {
          'mt-24': backgroundUrl
        })}
      >
        {children}
      </div>
      <div className='relative w-full max-w-xl mx-auto'>
        {showTerms && <Terms className='pt-8 px-4' />}
        <Footer />
      </div>
    </div>
  )
}
